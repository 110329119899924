/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import MainMenu from './MainMenu'
import styled, {createGlobalStyle} from 'styled-components';
import {graphql, StaticQuery} from 'gatsby';
import { Helmet } from "react-helmet"




const GlobalStyle = createGlobalStyle `
  body{
    margin:0;
    padding:0;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5
  }
`
const LayoutWrapper = styled.div
  `max-width:960px;
  margin: 0 auto`

const Layout = ({ children }) => (
  <div>
  <StaticQuery query={graphql`
    {
      allWordpressWpFavicon{
        edges{
          node{
            url{
              source_url
            }
          }
        }
      }
    }
  `} render={props => <Helmet><link rel="icon" href={props.allWordpressWpFavicon.edges[0].node.url.source_url} /></Helmet>} />

    <GlobalStyle />
    <MainMenu />
    <LayoutWrapper>
    { children }
    </LayoutWrapper>
  </div>
)


export default Layout
