import React from 'react'
import {graphql, StaticQuery} from 'gatsby'

const Logo = () => (
  <StaticQuery query = {graphql
`{
      allWordpressWpLogo {
        edges {
          node {
            url {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }`
  }
  render = { props => (
    <img src={props.allWordpressWpLogo.edges[0].node.url.localFile.publicURL} alt='portfolio shows this case study'/>
  ) } />
);

export default Logo;
